<template>
  <div class="p-container">
    <p class="en-title">PRODUCT SERIES</p>
    <p class="cn-title">推荐</p>
    <div class="item">
      <div v-for="(item, index) in list" :key="index" class="list">

        <div
          class="image"
        >
            <img :src="item.pic.picUrl" alt="">
        </div>
        
        <p class="title">{{ item.title }}</p>

        <div class="price">
          <p>{{ item.minpriceStr }}</p>



          <div class="add-btn" @click="clickAdd(item,$event)">
            加入购物车
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ADD_SHPPING } from "@/service/shoppingCar";
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    getdata:{
      type:Function,
      default:()=>{}
    }
  },

  methods: {
    /**
     * @title 加入购物车
     */
    clickAdd(item,event) {
      ADD_SHPPING({ uid:1, pnum:1, skuid: 0, productid: item.id}).then(res => {
        const code = res.status
        if (code === 200) {
           var offset = $(".spw").offset();
            var addcar = $(this);
            var img = $(event.target)
              .parent()
              .parent()
              .find('.image')
              .find('img')
              .attr("src");
              console.log(img)
            var flyer = $(
              '<img class="u-flyer" src="' + img + '" style="z-index:99999;border-radius: 50%;width:50px;height:50px">'
            );
            flyer.fly({
              start: {
                left: event.pageX, //开始位置（必填）#fly元素会被设置成position: fixed
                top: event.pageY //开始位置（必填）
              },
              end: {
                left: offset.left + 10, //结束位置（必填）
                top: offset.top + 10, //结束位置（必填）
                width: 0, //结束时宽度
                height: 0 //结束时高度
              },
              onEnd: function() {
                //结束回调
                $("#msg")
                  .show()
                  .animate({ width: "250px" }, 200)
                  .fadeOut(1000); //提示信息
                addcar
                  .css("cursor", "default")
                  .removeClass("orange")
                  .unbind("click");
              }
            });
            this.$store.commit("user/SETCAR", res.attachment.total);
          this.getdata();

          this.$notify({
            title: "成功",
            message: res.message,
            type: "success"
          });
        } else if(code === 204) {
          this.$notify({
            title: "失败",
            message: res.message,
            type: "error"
          });
          this.$router.push({
            path: '/productInfo.html',
            query: { id: item.id }
          })
        } else {
          this.$notify({
            title: "失败",
            message: res.message,
            type: "error"
          });
        }
      })
    }
  }
};
</script>

<style lang="less" scoped>
.p-container {
  margin-top: 200px; /* no */
}
.en-title {
  font-size: 24px; /* no */
  text-align: center;
}
.cn-title {
  margin-top: 15px; /* no */
  font-size: 38px; /* no */
  text-align: center;
}

.item {
  display: flex;
  justify-content: center;
  margin-top: 50px; /* no */
  margin-bottom: 200px; /* no */
  .list {
    margin-right: 20px; /* no */
    width: 290px; /* no */
    .image {
      width: 290px; /* no */
      height: 278px; /* no */
      overflow: hidden;
      // background-size: cover;
      // background-repeat: no-repeat;
      // background-position: center;
      img{
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .title {
      font-size: 20px; /* no */
      color: #000000;
      padding: 20px 5px; /* no */
      width: 100%; /* no */
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
    }
    .price {
      padding: 0 5px; /* no */
      font-size: 20px; /* no */
      color: #000000;
      display: flex;
      justify-content: space-between;
      align-content: center;
      p {
        line-height: 32px; /* no */
      }
      .add-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 128px; /* no */
        height: 32px; /* no */
        background: #921d22;
        font-size: 17px; /* no */
        color: #ffffff;
        border-radius: 16px; /* no */
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .item {
     .list {
        width: 190px; /* no */
        .image {
          width: 190px; /* no */
          height: 178px; /* no */
        }
     }
     .price {
        font-size: 16px; /* no */
        .add-btn {
          width: 90px; /* no */
          font-size: 13px; /* no */
        }
      }
  }
}
@media only screen and (min-width: 640px) and (max-width: 960px) {
  .item {
     .list {
        width: 190px; /* no */
        margin-right: 30px; /* no */
        .image {
          width: 190px; /* no */
          height: 178px; /* no */
        }
        .price {
          font-size: 16px; /* no */
          .add-btn {
            width: 90px; /* no */
            font-size: 13px; /* no */
          }
        }
     }
  }
}
@media only screen and (min-width: 960px) and (max-width: 1200px) {
  .item {
     .list {
        width: 190px; /* no */
        margin-right: 40px; /* no */
        .image {
          width: 190px; /* no */
          height: 178px; /* no */
        }
        .price {
          font-size: 16px; /* no */
          .add-btn {
            width: 90px; /* no */
            font-size: 13px; /* no */
          }
        }
     }
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .item {
    .list {
      width: 230px; /* no */
      margin-right: 70px; /* no */
      .image {
        width: 230px; /* no */
        height: 218px; /* no */
      }
    }
  }
}

</style>
