<template>
  <div class="container">
    <div class="title">购物车</div>
    <div class="table">
      <div class="table-header">
        <p>产品名称</p>
        <p>单价</p>
        <p>数量</p>
        <p>总计</p>
      </div>
      <div v-for="(item, index) in shoppingList" :key="index" class="table-list">
        <div class="name">
          <el-checkbox class="checkbox-custom" v-model="item.state">
            <div class="image" :style="{ backgroundImage: `url(${item.pic.picUrl})` }"></div>
          </el-checkbox>
          <div class="image-info">
            <p>{{ item.title }}</p>
            <p>
              {{ item.drskudes || "" }}
              {{ item.dskudes || "" }}
            </p>
          </div>
        </div>
        <div class="price">￥{{ item.price | amount }}</div>
        <div class="num">
          <div class="counter">
            <img src="@/assets/images/shoppingCar/-.png" alt @click="handleNum(index, 'min')" />
            <p>{{ item.pnum }}</p>
            <img src="@/assets/images/shoppingCar/+.png" alt @click="handleNum(index, 'add')" />
          </div>
        </div>
        <div class="total">
          <p>￥{{ item.price * item.pnum | amount }}</p>
          <img src="@/assets/images/shoppingCar/delete-ico.png" @click="clickDel(item.id)" alt />
        </div>
      </div>
      <div v-if="shoppingList.length === 0" class="no-data">暂无商品</div>

      <div class="table-footer">
        <div class="info">
          <div>总计</div>
          <div>{{ totalNum }}件</div>
          <div>￥{{ totalPrice | amount}}</div>
        </div>
        <div class="handle">
          <div @click="clickClear">清空购物车</div>
          <div @click="orderCoinfo">结算</div>
        </div>
      </div>
    </div>

    <push-pros :list="pushProsList" :getdata='getData'/>
  </div>
</template>

<script>
import {
  GET_SHPPING_LIST,
  DEL_SHPPING,
  EDIT_SHPPING,
  ORDER_COINFO
} from "@/service/shoppingCar";
import PushPros from "./components/PushPros";
let timer = null;
export default {
  name: "Shopping",

  filters: {
    amount(number) {
      return isNaN(number) ? 0.0 : parseFloat((number / 100).toFixed(2));
    }
  },

  components: {
    PushPros
  },

  data() {
    return {
      shoppingList: [],
      copyShoppingList: [],
      pushProsList: []
    };
  },

  created() {
    this.getData();
  },

  computed: {
    totalNum() {
      let num = 0;
      this.shoppingList.map(val => {
        if (val["state"] && val.state === true) {
          num += val.pnum;
        }
      });
      return num;
    },

    totalPrice() {
      let num = 0;
      this.shoppingList.map(val => {
        if (val["state"] && val.state === true) {
          num += val.price * val.pnum;
        }
      });
      return num;
    }
  },

  methods: {
    /**
     * @title 获取购物车列表
     */
    getData() {
      GET_SHPPING_LIST({ uid: 1 }).then(res => {
        this.shoppingList = res.attachment.datas || [];
        // copy 数据 做修改商品数量时的回滚操作
        this.copyShoppingList =
          JSON.parse(JSON.stringify(this.shoppingList)) || [];
        this.pushProsList = res.attachment.pushPros;
      });
    },

    /**
     * @title 删除购物车
     * @param { id } Number
     */
    clickDel(id) {
      let a = confirm('确定删除吗？删除后无法恢复')
      if(a){
        DEL_SHPPING({ uid: 1, ids: id }).then(res => {
        if (res.status == 200) {
          this.getData();
          // this.$notify({
          //   title: "成功",
          //   message: res.message,
          //   type: "success"
          // });
          this.$store.commit("user/SETCAR", res.attachment.total);
        } else {
          // this.$notify({
          //   title: "失败",
          //   message: res.message,
          //   type: "error"
          // });
        }
      });
      }else{
        return
      }
    },

    /**
     * @title 清空购物车
     */
    clickClear() {
      let ids = [];
      this.shoppingList.map(val => {
        ids.push(val.id);
      });
      if (ids.length > 0) {
        this.clickDel(ids.join());
      } else {
        this.$store.commit("user/SETCAR", 0);
        // this.$message({
        //   type: "warning",
        //   message: "购物车为空"
        // });
      }
    },

    /**
     * @title 修改购物车
     * @param { index, type }
     */
    handleNum(index, type) {
      const id = this.shoppingList[index].id;

      if (type === "add") {
        this.shoppingList[index].pnum++;
      }

      if (type === "min") {
        this.shoppingList[index].pnum > 1 && this.shoppingList[index].pnum--;
      }

      let pnum = this.shoppingList[index].pnum;

      if (timer != null) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        EDIT_SHPPING({ uid: 1, pnum: pnum, id: id }).then(res => {
          if (res.status == 200) {
            this.getData();
            this.$notify({
              title: "成功",
              message: res.message,
              type: "success"
            });
          } else {
            this.shoppingList[index].pnum = this.copyShoppingList[index].pnum;
          }
        });
      }, 300);
    },

    /**
     * @title 结算
     */
    orderCoinfo() {
      let propertis = {
        products: []
      };
      this.shoppingList.map(val => {
        if (val["state"] && val.state === true) {
          propertis.products.push({
            pid: val.pid,
            skuid: val.skuid,
            flag: val.flag,
            pnum: val.pnum,
            color: val.color || "",
            carat: val.carat || "",
            shape: val.shape || "",
            buycarid:val.id,
            sizeid:val.sizeid
          });
        }
      });
      if (propertis.products.length > 0) {
        propertis.products.map(val => {
          // val.push({buycarid:val.id})
          for (const key in val) {
            if (val[key] === "" || val[key] === null) {
              delete val[key];
            }
          }
            console.log(val)

        });
        ORDER_COINFO({
          propertis: JSON.stringify(propertis),
          uid: 1,
          f: 2
        }).then(res => {
          if (res.status == 200) {
            this.$store.dispatch("order/orderInfo", res.attachment);
            this.$router.push({ path: "/confirmOrder" });
          } else {
            this.$notify({
              title: "失败",
              message: res.message,
              type: "error"
            });
          }
        });
      } else {
        this.$message({
          type: "warning",
          message: "请选择商品"
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.no-data {
  text-align: center;
  font-size: 30px; /* no */
  color: #000000;
  padding: 60px 0; /* no */
}
.checkbox-custom {
  margin: 0 18px 0 26px; /* no */
  display: flex;
  /deep/ .el-checkbox__inner {
    width: 24px; /* no */
    height: 24px; /* no */
  }
  /deep/ .is-checked .el-checkbox__inner::after {
    width: 6px; /* no */
    height: 14px; /* no */
    left: 7px; /* no */
    top: 0px; /* no */
    border-width: 3px; /* no */
    border-color: #921d22;
    transition: initial;
  }
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #ffffff;
    border-color: #cccccc;
  }
  /deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #cccccc;
  }
}
.container {
  padding: 0 360px;
  box-sizing: border-box;
  min-width: 960px; /* no */
}
.title {
  font-size: 30px; /* no */
  color: #000000;
  padding: 35px 0; /* no */
  border-bottom: 1px solid #cfcfcf; /* no */
}
.table {
  margin-top: 30px; /* no */
  .table-header {
    display: flex;
    align-items: center;
    background: #921d22;
    height: 60px; /* no */
    p {
      font-size: 18px; /* no */
      color: #ffffff;
      width: 16%;
      text-align: center;
      &:nth-child(1) {
        width: 52%;
      }
    }
  }
  .table-list {
    height: 290px; /* no */
    width: 100%;
    display: flex;
    align-items: center;
    background: #ffffff;
    border-bottom: 1px solid #cfcfcf;
    div {
      width: 16%;
      &:nth-child(1) {
        width: 52%;
      }
    }

    .name,
    .price,
    .total,
    .num {
      font-size: 18px; /* no */
      padding: 60px 0 30px 0; /* no */
    }

    .name {
      display: flex;
      align-items: flex-start;
      .image {
        width: 200px;
        height: 200px;
        box-shadow: 1px 1px 10px #cfcfcf; /* no */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
      .image-info {
        font-size: 20px; /* no */
        margin-left: 15px; /* no */
        flex: 1;
        p {
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        p:nth-child(1) {
          margin-top: 33px; /* no */
        }
        p:nth-child(2) {
          margin-top: 36px; /* no */
          font-size: 18px; /* no */
        }
      }
    }
    .num {
      font-size: 20px; /* no */
      color: #333333;
      text-align: center;
      .counter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 135px; /* no */
        height: 50px; /* no */
        padding: 0 20px; /* no */
        border: 1px solid #cccccc; /* no */
        img {
          width: 10px; /* no */
          cursor: pointer;
        }
      }
    }

    .price,
    .total {
      display: flex;
      align-items: center;
      font-size: 18px; /* no */
      color: #666666;
      justify-content: center;
      img {
        width: 20px; /* no */
        height: 20px; /* no */
        margin-left: 28px; /* no */
        cursor: pointer;
      }
    }
  }
  .table-footer {
    .info {
      display: flex;
      align-items: center;
      height: 136px; /* no */
      border-bottom: 1px solid #cfcfcf; /* no */
      font-size: 18px; /* no */
      div {
        width: 16%;
        text-align: center;
        &:nth-child(1) {
          width: 68%;
          text-align: left;
        }
      }
    }
    .handle {
      display: flex;
      justify-content: space-between;
      margin-top: 58px; /* no */
      div {
        width: 245px; /* no */
        height: 50px; /* no */
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:nth-child(1) {
          background: #ffffff;
          border: 1px solid #cccccc;
          font-size: 16px; /* no */
          color: #333333;
        }
        &:nth-child(2) {
          background: #921d22;
          border: 1px solid #921d22;
          font-size: 16px; /* no */
          color: #ffffff;
        }
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .container {
    padding: 0;
  }
}
@media only screen and (min-width: 640px) and (max-width: 960px) {
  .container {
    padding: 0;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1200px) {
  .container {
    padding: 0 60px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .container {
    padding: 0 160px;
  }
}
</style>
