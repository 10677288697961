import { get, post } from "../common/query";

export const GET_SHPPING_LIST = data => get("/buycar/myBuyCar", data);
export const DEL_SHPPING = data => post("/buycar/del", data);
export const EDIT_SHPPING = data => post("/buycar/edit", data);
// 添加购物车
export const ADD_SHPPING = data => post("/buycar/add", data);
// 购物车结算
export const ORDER_COINFO = data => post("/order/coInfo", data);


